import orderBy from "@/utils/orderBy";

export default {
  namespaced: true,
  state: {
    data: [],
    nomes: [],
    instrutoresFiltrados: [],
    instrutoresPeriodo: [],
    pagina: 1,
  },
  getters: {
    getPagina(state) {
      return state.pagina
    },
    getInstrutoresPaginado(state) {
      return state.instrutoresPaginado;
    },
    getData(state) {
      return state.data;
    },
    getNomes(state) {
      return state.nomes;
    },
    getQuantidadeInstrutores(state) {
      return state.quantidadeInstrutores
    },
    getFiltrarInstrutoresPorTreinamento(state) {
      return state.instrutoresFiltrados;
    },
    getFiltrarInstrutoresPorPeriodo(state) {
      return state.instrutoresPeriodo;
    }
  },
  mutations: {
    setData(state, payload) {
      let nomeInstrutores = []
      payload.filter((instrutor) => instrutor.status).map((instrutor) => {
        nomeInstrutores.push(instrutor.nome)
      })
      state.data = orderBy.nome(payload)
      state.nomes = orderBy.letters(nomeInstrutores)
    },
    editInstrutor(state, payload) {
      state.data[payload.index] = payload.data;
    },
    deleteInstrutor(state, id) {
      state.data = state.data.filter((item) => item.id != id);
    },
    setFiltrarInstrutoresPorTreinamento(state, payload) {
      state.instrutoresFiltrados = payload;
    },
    setFiltrarInstrutoresPorPeriodo(state, payload) {
      state.instrutoresPeriodo = payload;
    },
    setQuantidadeInstrutores(state, payload) {
      state.quantidadeInstrutores = payload
    },
    setPagina(state, payload) {
      state.pagina = payload
    },
    setInstrutoresPaginado(state, payload) {
      state.instrutoresPaginado = payload
    }
  },
  actions: {},
  modules: {},
};
