export default {
  namespaced: true,
  state: {
    data: [],
    estatisticasAnuais: [],
    meses: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez'
    ]
  },

  getters: {
    getData(state) {
      return state.data;
    },
    getEstatisticasAnuais(state) {
      const getNames = (index) => {
        return state.estatisticasAnuais[index].porcentagensDashboard[0].porcentagens.map((item) => item.name)
      }
      const getSeries = (estatisticas, name) => {
        return estatisticas
          .map((estatistica) => estatistica.porcentagens
            .filter((porcentagem) => porcentagem.name == name)
            .map((porcentagem) => porcentagem.data[0])
          )
      }

      return state.estatisticasAnuais.map(
        function (item, index) {
          const estatisticas = state.estatisticasAnuais[index].porcentagensDashboard
          const names = getNames(index)


          return {
            pergunta: item.pergunta,
            categories: state.meses,
            series: names.map(
              function (name) {
                return {
                  name: name,
                  data: getSeries(estatisticas, name)
                }
              })
          }
        });
    },
  },
  mutations: {
    setData(state, payload) {
      state.data = payload;
    },
    limparState(state) {
      state.data = [];
    },
    setEstatisticasAnuais(state, payload) {
      state.estatisticasAnuais = payload;
    }
  },
  actions: {},
  modules: {},
};
