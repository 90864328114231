import orderBy from "@/utils/orderBy";

export default {
  namespaced: true,
  state: {
    data:[],
    treinamentosPaginado: [],
    quantidadeTreinamentos: 0,
    pagina: 1,
    nomes: [],
    treinamentosFiltrados: []
  },
  getters: {
    getData(state) {
      return state.data;
    },
    getPagina(state) {
      return state.pagina
    },
    getNomes(state) {
      return state.nomes;
    },
    getTreinamentosPaginado(state) {
      return state.treinamentosPaginado
    },
    getQuantidadeTreinamentos(state) {
      return state.quantidadeTreinamentos
    },
    getFiltrarTreinamentoPorPeriodo(state){
        return state.treinamentosFiltrados;
    }
  },
  mutations: {
    setData(state, payload) {
      let nomeTreinamentos = []
      payload.filter((treinamento) => treinamento.status).map((treinamento) => {
        nomeTreinamentos.push(treinamento.nome)
      })
      state.data = orderBy.nome(payload)
      state.nomes = orderBy.letters(nomeTreinamentos)
    },
    setPagina(state, payload) {
      state.pagina = payload
    },
    setQuantidadeTreinamentos(state, payload) {
      state.quantidadeTreinamentos = payload
    },
    setTreinamentosPaginado(state, payload) {
      state.treinamentosPaginado = payload
    },
    setFiltrarTreinamentoPorPeriodo(state, payload){
      state.treinamentosFiltrados = payload;
    },
    editTreinamento(state, payload) {
      state.data[payload.index] = payload.data;
    },
    deleteTreinamento(state, id) {
      state.data = state.data.filter((item) => item.id != id);
    }
  },
  actions: {},
  modules: {},
};
