<script setup>
import store from "@/store";
import { ref } from 'vue'

const themeIsDark = ref(store.state.themeIsDark)

const changeTheme = () => {
  store.commit("setThemeIsDark", !themeIsDark.value);
};
</script>

<template>
  <div class="toggle custom--revert" data-cooltipz-dir="bottom" aria-label="Ativar modo claro" v-if="themeIsDark">
    <input v-model="themeIsDark" id="switch" type="checkbox" name="theme" @click="changeTheme" />
    <label for="switch">Toggle</label>
  </div>
  <div class="toggle custom--revert" data-cooltipz-dir="bottom" aria-label="Ativar modo escuro" v-else>
    <input v-model="themeIsDark" id="switch" type="checkbox" name="theme" @click="changeTheme" />
    <label for="switch">Toggle</label>
  </div>
</template>

<style lang="scss" scoped>
.toggle {
  display: flex;
  align-items: center;
}

h1 {
  margin: 0;
  color: var(--background);
}

p {
  color: var(--text);
  grid-area: content;
  font-size: 1.1em;
  line-height: 1.8em;
  margin-top: 2em;
}

input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 52px;
  height: 27px;
  background: grey;
  float: right;
  border-radius: 100px;
  position: relative;

  @media screen and (max-width: 450px) {
    width: 25px;
    height: 15px;
  }
}

label::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 90px;
  transition: 0.3s;

  @media screen and (max-width: 450px) {
    width: 10px;
    height: 10px;
  }
}

input:checked+label {
  background-color: var(--secondary);
}

input:checked+label::after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 45px;
}
</style>
