import { createRouter, createWebHistory } from 'vue-router'
import { computed } from "@vue/reactivity";
import store from "@/store";

const usuario = computed(() => store.getters['authModule/getUser'])
const isInstrutor = computed(() => store.getters['authModule/isInstrutor'])
const isGestor = computed(() => store.getters['authModule/isGestor'])

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/pages/DashboardView.vue')
  },
  {
    path: '/formularios/',
    name: 'Formulários',
    meta: {
      showNavbar: true
    },
    component: () => import('@/pages/FormulariosView.vue')
  },
  {
    path: '/relatorios/',
    name: 'Relatórios',
    meta: {
      showNavbar: true
    },
    component: () => import('@/pages/RelatoriosView.vue')
  },
  {
    path: '/instrutores/',
    name: 'Instrutores',
    meta: {
      showNavbar: (usuario.value.privilegio !== 'INSTRUTOR')
    },
    component: () => import('@/pages/InstrutoresView.vue')
  },
  {
    path: '/treinamentos/',
    name: 'Treinamentos',
    meta: {
      showNavbar: (usuario.value.privilegio !== 'INSTRUTOR')
    },
    component: () => import('@/pages/TreinamentosView.vue')
  },
  {
    path: '/avaliacoes/',
    name: 'Avaliações',
    meta: {
      showNavbar: (usuario.value.privilegio !== 'INSTRUTOR')
    },
    component: () => import('@/pages/AvaliacoesView.vue')
  },
  {
    path: '/feedback/:id',
    name: 'Feedback',
    meta: {
      showNavbar: false
    },
    component: () => import('@/pages/FeedbackView.vue')
  },
  {
    path: '/unauthorized/',
    name: 'Unauthorized',
    meta: {
      showNavbar: false
    },
    component: () => import('@/pages/UnauthorizedView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((route) => {
  if(route.name == 'Feedback'){
    return
  }else{
    if (route.name == 'Dashboard' && (isInstrutor.value || isGestor.value))
      return window.location.href = '/formularios/'
    if (route.name == 'Unauthorized' && usuario.value.status) 
      return window.location.href = '/'
    if (route.name !== 'Dashboard' && route.name !== 'Unauthorized' && route.name !== 'Feedback' && !route.meta.showNavbar)
      return window.location.href = '/'
    if (!usuario.value.status && !window.location.href.includes('/unauthorized/')) 
      return window.location.href = '/unauthorized/'
  }
})

export default router
