<script setup>
import router from "@/router";
import { computed } from "vue";
import store from "@/store";

const expand = computed(() => store.state.expandirBarraLateral);
const themeIsDark = computed(() => store.state.themeIsDark);
const rotas = computed(() => router.getRoutes().filter((rota) => rota.meta.showNavbar));

function fecharBarraLateral() {
  store.commit('setExpandirBarraLateral', false);
}

function expandirBarraLateral() {
  store.commit('setExpandirBarraLateral', !expand.value);
}
</script>

<template>
  <nav :class="{ 'navbar expand': expand, 'navbar is-hidden-mobile': !expand, 'navbar modo-escuro is-hidden-mobile': themeIsDark }"
    v-click-outside="fecharBarraLateral" title="sidebar" id="sidebar">
    <ul>
      <li @click="expandirBarraLateral" data-tooltip :title="expand ? 'Retrair' : 'Expandir'">
        <div class="nav-item">
          <div class="icon-box">
            <font-awesome-icon class="icon" icon="fa-solid fa-bars" />
          </div>
        </div>
      </li>
      <span v-for="rota in rotas" :key="rota.name">
        <li>
          <router-link :to="rota.path" data-tooltip :title="rota.name" @click="fecharBarraLateral" class="nav-item">
            <div class="icon-box">
              <font-awesome-icon class="icon" :icon="new Map(store.state.icones).get(rota.name)" />
            </div>
            <span v-if="expand">{{ rota.name }}</span>
          </router-link>
        </li>
      </span>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.button {
  position: absolute;
  left: .5rem;
  top: 3rem;
  border-radius: 50%;
  background-color: transparent;
  border-color: transparent;
  color: var(--tertiary);
}

.navbar {
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  width: 4rem;
  overflow: hidden;
  height: 100%;
  transition: 0.3s;
  box-shadow: var(--primary) 0px 5px 5px;

  ul,
  li,
  a {
    width: 100%;
  }

  ul {
    li {
      display: flex;
      height: 10vh;
      color: var(--primary);

      .nav-item {
        color: var(--primary);
        height: 100%;
        display: flex;
        align-items: center;
        width: 100%;

        .icon-box {
          width: 3.6rem;
          display: flex;
          justify-content: center;
          position: relative;

          .icon {
            width: 100%;
          }
        }
      }

      .router-link-active {
        background-color: var(--secondary);
        border-left: 3px solid var(--primary);
      }
    }

    :hover {
      background-color: var(--secondary);
      cursor: pointer;

      li {
        border-left: 3px solid var(--primary);
      }

      a,
      .icon,
      .icon-box,
      span {
        border: none;
      }
    }
  }
}

.expand {
  width: 16rem;

  .nav-item {
    display: grid;
    column-gap: 0;

    .icon-box {
      grid-row: 1;
      grid-column: 1;
    }

    span {
      grid-row: 1;
      grid-column: 2;
      text-align: left;
      position: fixed;
      left: 4rem;
    }
  }

  @media screen and (max-width: 860px) {
    position: fixed;
  }
}

@media print {
  .navbar {
  display: none;
  }
}
</style>
