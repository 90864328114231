import store from "@/store"
import axios from "axios"

const http = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

http.interceptors.request.use(
  (config) => {
    let token = store.getters["authModule/getToken"];
    config.headers.Authorization = "Bearer " + token;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default http;
