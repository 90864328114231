const orderBy = {
  nome(payload) {
    return payload.sort((a, b) => {
      let x = a.nome.toLocaleLowerCase(),
          y = b.nome.toLocaleLowerCase()
      return x == y ? 0 : x > y ? 1 : -1
    })
  },
  letters(payload) {
    return payload.sort((a, b) => {
      let x = a.toLocaleLowerCase(),
          y = b.toLocaleLowerCase()
      return x == y ? 0 : x > y ? 1 : -1
    })
  }
}

export default orderBy