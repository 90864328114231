import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faCalendar,
  faCircle,
  faCircleCheck,
  faCopy,
  faFaceFrown,
  faSquarePlus,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons"
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faArrowLeft,
  faArrowRightFromBracket,
  faAward,
  faBars,
  faChartPie,
  faCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleMinus,
  faCirclePlus,
  faCircleQuestion,
  faCircleUser,
  faClipboardList,
  faCommentDots,
  faEye,
  faFileLines,
  faFilePdf,
  faGraduationCap,
  faHourglassEnd,
  faHourglassStart,
  faLightbulb,
  faMagnifyingGlass,
  faPenToSquare,
  faPersonChalkboard,
  faPlus,
  faSliders,
  faStarOfLife,
  faSun,
  faTrash,
  faXmark
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import vClickOutside from "click-outside-vue3"
import "cooltipz-css"
import Keycloak from "keycloak-js"
import VCalendar from "v-calendar"
import "v-calendar/dist/style.css"
import { createApp } from "vue"
import VueTheMask from "vue-the-mask"
import Toast from "vue-toastification"
import App from "./App.vue"
import router from "./router/index"
import InstrutorAPI from "./services/InstrutorAPI"
import store from "./store"

library.add(
  faArrowRightFromBracket,
  faSun,
  faBars,
  faClipboardList,
  faChartPie,
  faPersonChalkboard,
  faGraduationCap,
  faCircleQuestion,
  faMagnifyingGlass,
  faSliders,
  faPlus,
  faCalendar,
  faCommentDots,
  faLightbulb,
  faAnglesRight,
  faAngleRight,
  faAnglesLeft,
  faAngleLeft,
  faCircleUser,
  faPenToSquare,
  faCircleMinus,
  faCirclePlus,
  faFileLines,
  faTrash,
  faCheck,
  faXmark,
  faSquarePlus,
  faCircle,
  faTrashCan,
  faFilePdf,
  faCircleExclamation,
  faStarOfLife,
  faHourglassEnd,
  faCircleCheck,
  faCopy,
  faAward,
  faEye,
  faHourglassStart,
  faFaceFrown,
  faCircleInfo,
  faArrowLeft
);

const initOptions = {
  url: process.env.VUE_APP_CONTAS_URL,
  realm: process.env.VUE_APP_CONTAS_REALM,
  clientId: process.env.VUE_APP_CONTAS_CLIENTID,
};

const keyCloack = new Keycloak(initOptions);

function createVue() {
  createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(store)
    .use(router)
    .use(VCalendar, { datePicker: { popover: { placement: "top-end" } } })
    .use(VueTheMask)
    .use(vClickOutside)
    .use(Toast)
    .mount("#app");
}

export const logout = () => {
  keyCloack.logout();
};

const pathname = window.location.pathname;
const pathnameArray = pathname.split("/");
const firstPathname = pathnameArray[1];

if (firstPathname == "feedback") {
  createVue();
} else {
  keyCloack
    .init({
      onLoad: "login-required",
      checkLoginIframe: false,
    })
    .then(async (auth) => {
      if (!auth) {
        window.location.reload();
      } else if (!keyCloack.realmAccess.roles.includes(process.env.VUE_APP_FAT_PAPEL_ADMINISTRADOR)) {
        alert("Acesso não autorizado!");
        logout();
      } else {
        store.commit("authModule/changeToken", keyCloack.token);
        store.commit("authModule/changeRole", keyCloack.realmAccess.roles);
        store.commit(
          "authModule/changeName",
          keyCloack.idTokenParsed.given_name
        );
        await InstrutorAPI.getByLogin(
          keyCloack.idTokenParsed.preferred_username
        );

        createVue();

        setInterval(() => {
          keyCloack
            .updateToken(180)
            .then((refreshed) => {
              if (refreshed)
                store.commit("authModule/changeToken", keyCloack.token);
            })
            .catch(() => {});
        }, 10000);
      }
    });
}
