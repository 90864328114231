export default {
  namespaced: true,
  state: {
    perguntas: [],
    perguntaSelecionada: "",
  },
  getters: {
    getPerguntas(state) {
      return state.perguntas;
    },
    getPergunta(state) {
      return state.perguntaSelecionada;
    },
  },
  mutations: {
    setPerguntas(state, payload) {
      state.perguntas = payload;
    },
    setPergunta(state, payload) {
      state.perguntaSelecionada = payload;
    },
  },
  actions: {},
  modules: {},
};
