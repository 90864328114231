<script setup>
import SideBar from "@/components/SideBar/SideBar.vue";
import ToolBar from "@/components/ToolBar/ToolBar.vue";
import { computed } from 'vue';
import store from "@/store";

const themeIsDark = computed(() => store.state.themeIsDark)

</script>

<template>
    <SideBar v-if="$route.meta.showNavbar" />
    <div id="content" :class="{'modo-escuro': themeIsDark}">
      <ToolBar :class="{'modo-escuro': themeIsDark}" v-if="$route.name != 'Feedback'"/>
      <router-view :class="{'modo-escuro': themeIsDark}"/>
    </div>
</template>

<style lang="scss">
#content {
  width: 100vw;
  overflow: auto;
  background-color: var(--secondary);
}
</style>
