export default {
  namespaced: true,
  state: {
    avaliacoesPaginado: [],
    quantidadeAvaliacoes: 0,
    pagina: 1,
    tokenGerado: '',
    avaliacoesRespondidas: [],
    acessoAvaliacao: []
  },
  getters: {
    getPagina(state) {
      return state.pagina
    },
    getAvaliacoesPaginado(state) {
      return state.avaliacoesPaginado
    },
    getQuantidadeAvaliacoes(state) {
      return state.quantidadeAvaliacoes
    },
    getTokenGerado(state) {
      return state.tokenGerado
    },
    getAvaliacoesRespondidas(state) {
      return state.avaliacoesRespondidas
    },
    getAcessoAvaliacao(state) {
      return state.acessoAvaliacao
    }
  },
  mutations: {
    setPagina(state, payload) {
      state.pagina = payload
    },
    setAvaliacoesPaginado(state, payload) {
      state.avaliacoesPaginado = payload
    },
    setQuantidadeAvaliacoes(state, payload) {
      state.quantidadeAvaliacoes = payload
    },
    setTokenGerado(state, payload) {
      state.tokenGerado = payload
    },
    setAvaliacoesRespondidas(state, payload) {
      state.avaliacoesRespondidas.push(payload)
    },
    setAcessoAvaliacao(state, payload) {
      if (state.acessoAvaliacao.some((e) => e.id == payload.id)) {
        state.acessoAvaliacao[state.acessoAvaliacao.indexOf(state.acessoAvaliacao.filter((acesso) => 
          acesso.id == payload.id)[0])].disponibilidade = payload.disponibilidade
      } else {
        state.acessoAvaliacao.push(payload)
      }
    }
  },
};