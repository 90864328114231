export default {
  namespaced: true,
  state: {
    comentarios: [],
    sugestoes: []
  },

  getters: {
    getComentarios(state) {
      return state.comentarios;
    },
    getSugestoes(state) {
      return state.sugestoes;
    },
  },
  mutations: {
    setComentarios(state, payload) {
      state.comentarios = payload;
    },
    setSugestoes(state, payload) {
      state.sugestoes = payload;
    },
    limparState(state){
      state.comentarios = [];
      state.sugestoes = [];
      state.comentariosSugestoes = [];
    }
  },
  actions: {},
  modules: {},
};
