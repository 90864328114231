<script setup>
import DarkMode from "@/components/DarkMode/DarkMode.vue";
import { logout } from "@/main";
import store from "@/store";
import { computed } from "vue";

const isInstrutor = computed(() => store.getters['authModule/isInstrutor'])
const isGestor = computed(() => store.getters['authModule/isGestor'])

function expandirBarraLateralMobile() {
  const sideBar = document.querySelector("#sidebar");

  store.commit("setExpandirBarraLateral", true);
  sideBar.classList.remove("is-hidden-mobile");
  sideBar.classList.add("expand");
}
</script>

<template>
  <div class="toolbar-container">
    <button
      class="button is-hidden-tablet"
      @click="expandirBarraLateralMobile"
      v-if="$route.meta.showNavbar"
    >
      <font-awesome-icon class="icon" icon="fa-solid fa-bars" />
    </button>
    <div class="toolbar-logo">
      <button class="custom--revert" data-cooltipz-dir="bottom" :aria-label="isInstrutor || isGestor ? 'Ir para Gerar Link' : 'Acessar o Dashboard'">
        <a :href="isInstrutor || isGestor ? '/formularios/' : '/'">
          <img
            v-if="store.state.themeIsDark"
            class="logotoolbar-svg"
            src="@/assets/UCA_logo_branca.png"
            alt="logo UCA-FAT"
          />
          <img
            v-else
            class="logotoolbar-svg"
            src='@/assets/UCA_logo.png'
            alt="logo UCA-FAT"
          />
        </a>
      </button>
      <div class="page-title custom--revert" data-cooltipz-dir="bottom" aria-label="Página atual">
        {{ ($route.name == 'Unauthorized') ? 'Acesso negado' : $route.name }}
      </div>
    </div>
    <ul>
      <li>
        <DarkMode />
      </li>
      <li>
        <a class="custom--revert" data-cooltipz-dir="bottom" aria-label="Sair" @click="logout">
          <font-awesome-icon
            class="icon"
            icon="fa-solid fa-arrow-right-from-bracket"
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.toolbar-container {
  width: 100%;
  height: auto;
  background-color: var(--background);
  padding: 0 1% 0 3%;
  box-shadow: var(--box-shadow-color) 0px 5px 15px;
  user-select: none;

  .button {
    background-color: transparent;
    color: var(--text);
    margin-right: 0.5rem;

    @media screen and (max-width: 450px) {
      font-size: 0.8rem;
    }
  }

  &,
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    list-style: none;

    a {
      text-decoration: none;
    }

    li {
      padding: 0 1.5rem 0 0;

      .icon {
        width: 1.3rem;
        height: 1.3rem;
        color: var(--text);

        @media screen and (max-width: 280px) {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }

  .toolbar-logo {
    display: flex;

    button {
      background-color: transparent;
      padding: 5px;
      cursor: pointer;
      border: 0;
      outline: 0;

      img {
        width: 7rem;
        height: 2.7rem;
        margin-right: 0.5rem;

        @media screen and (max-width: 1400px) {
          width: 5.5rem;
          height: 2rem;
        }

        @media screen and (max-width: 450px) {
          width: 3.7rem;
          height: 1.5rem;
        }

        @media screen and (max-width: 280px) {
          width: 4rem;
          height: 1.5rem;
        }
      }
    }
  }

  .page-title {
    display: flex;
    align-items: center;
    font-size: 1.7rem;
    color: var(--titulo-pagina);
    font-weight: bold;

    @media screen and (max-width: 450px) {
      font-size: 1.3rem;
      margin-right: 1rem;
    }

    @media screen and (max-width: 280px) {
      font-size: 0.8rem;
    }
  }
}

@media print {
  .toolbar-container {
    display: none;
  }
}
</style>
