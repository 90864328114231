export default {
    namespaced: true,
    state: {
      user: {
        privilegio: ""
      },
      name: "",
      role: {},
      token: ""
    },
    getters: {
      getUser(state) {
        return state.user;
      },
      getToken(state) {
        return state.token;
      },
      isAdmin(state) {
        return (state.user.privilegio == "ADMINISTRADOR")
      },
      isSupervisor(state) {
        return (state.user.privilegio == "SUPERVISOR_UCA")
      },
      isGestor(state) {
        return (state.user.privilegio == "GESTOR")
      },
      isInstrutor(state) {
        return (state.user.privilegio == "INSTRUTOR")
      }
    },
    mutations: {
      changeUser(state, payload) {
        state.user = payload;
      },
      changeName(state, payload) {
        state.name = payload;
      },
      changeRole(state, payload) {
        state.role = payload;
      },
      changeToken(state, payload) {
        state.token = payload;
      },
    },
    actions: {},
    modules: {},
  };

  