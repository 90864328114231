export default {
  namespaced: true,
  state: {
    layouts: [],
    layoutSelecionado: "",
    layoutEditado: "",
  },
  getters: {
    getData(state) {
      return state.layouts;
    },
    getLayoutSelecionado(state) {
      return state.layoutSelecionado;
    },
    getLayoutEditado(state) {
      return state.layoutEditado;
    },
    getLayoutPadrao(state) {
      const padrao = state.layouts.filter((layout) => layout.predefinido);
      return padrao[0];
    },
  },
  mutations: {
    setData(state, payload) {
      state.layouts = payload;
    },
    setLayoutSelecionado(state, payload) {
      state.layoutSelecionado = payload;
    },
    setPerguntaPadrao(state, payload){
      state.layouts.map((e)=>{
   
         let pergunta = e.perguntas.filter((evt)=>payload.id==evt.id)[0]
         
         if(payload.padrao){
           return pergunta ? "": e.perguntas.push(payload)
                       
          } if(pergunta){                  
           return pergunta.padrao? e.perguntas =  e.perguntas.filter((evt)=>payload.id!=evt.id): ""
          }
        })      
    },
    editLayout(state, payload) {
      state.layouts[payload.index] = payload.body;
      state.layoutSelecionado = payload.body;
    },
    editPergunta(state, payload) {
      state.layouts.forEach((layout) => {
        const index = layout.perguntas.indexOf(layout.perguntas.filter((pergunta) => pergunta.id == payload.id)[0])
        if (index != -1) {
          layout.perguntas.splice(index, 1, payload)
        }
      })
    },
    setLayoutEditado(state, payload) {
      state.layoutEditado = payload;
    },
  },
};
