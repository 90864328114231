import store from "@/store";
import { computed } from "vue";
import http from "./config";

const instrutores = computed(() => store.getters["instrutorModule/getData"]);
const isGestor = computed(() => store.getters["authModule/isGestor"]);
const isSupervisor = computed(() => store.getters["authModule/isSupervisor"]);
const usuario = computed(() => store.getters["authModule/getUser"]);
const paginaSelecionada = computed(
  () => store.getters["instrutorModule/getPagina"]
);

const InstrutorAPI = {
  async getInstrutores() {
    return http
      .get("/usuario")
      .then((response) => {
        if (isGestor.value)
          store.commit(
            "instrutorModule/setData",
            response.data.filter(
              (instrutor) =>
                (instrutor.canal == usuario.value.canal &&
                  instrutor.privilegio == "INSTRUTOR") ||
                instrutor.login == usuario.value.login
            )
          );
        else if (isSupervisor.value)
          store.commit(
            "instrutorModule/setData",
            response.data.filter(
              (instrutor) =>
                instrutor.privilegio == "INSTRUTOR" ||
                instrutor.privilegio == "GESTOR" ||
                instrutor.login == usuario.value.login
            )
          );
        else store.commit("instrutorModule/setData", response.data);
        store.commit("setErros", "");
      })
      .catch((error) => store.commit("setErros", error.response.data));
  },

  async getInstrutoresPaginado(pagina, filtroSelecionado = 'Ativos') {

    const chamada = (() => {
      switch (filtroSelecionado) {
        case 'Ativos':
          return 'ativos/';
        case 'Inativos':
          return 'inativos/';
        case 'Todos':
          return ''
      }
    })();

    const status = (() => {
      switch (filtroSelecionado) {
        case 'Ativos':
          return 'status/true';
        case 'Inativos':
          return 'status/false';
        case 'Todos':
          return ''
      }
    })();

    store.commit("setRequisicao", true);
    if (isGestor.value) {
      return http
        .get(
          `/usuario/gestor/${chamada}${usuario.value.id}/paginado/?pagina=${pagina}&tamanhoPagina=8`
        )
        .then((response) => {
          store.commit(
            "instrutorModule/setInstrutoresPaginado",
            response.data.content
          );
          store.commit(
            "instrutorModule/setQuantidadeInstrutores",
            response.data.totalElements
          );
          store.commit("setRequisicao", false);
          store.commit("setErros", "");
        })
        .catch((error) => {
          store.commit("setRequisicao", false);
          store.commit("setErros", error.response.data);
        });
    } else if (isSupervisor.value) {
      return http
        .get(
          `/usuario/supervisor/${chamada}${usuario.value.id}/paginado/?pagina=${pagina}&tamanhoPagina=8`
        )
        .then((response) => {
          store.commit(
            "instrutorModule/setInstrutoresPaginado",
            response.data.content
          );
          store.commit(
            "instrutorModule/setQuantidadeInstrutores",
            response.data.totalElements
          );
          store.commit("setRequisicao", false);
          store.commit("setErros", "");
        })
        .catch((error) => {
          store.commit("setRequisicao", false);
          store.commit("setErros", error.response.data);
        });
    } else {
      return http
        .get(`/usuario/paginado/${status}?pagina=${pagina}&tamanhoPagina=8`)
        .then((response) => {
          store.commit(
            "instrutorModule/setInstrutoresPaginado",
            response.data.content
          );
          store.commit(
            "instrutorModule/setQuantidadeInstrutores",
            response.data.totalElements
          );
          store.commit("setRequisicao", false);
          store.commit("setErros", "");
        })
        .catch((error) => {
          store.commit("setRequisicao", false);
          store.commit("setErros", error.response.data);
        });
    }
  },

  async getInstrutoresPaginadoFiltrado(pagina, filtro, filtroSelecionado = 'Ativos') {
    const chamada = (() => {
      switch (filtroSelecionado) {
        case 'Ativos':
          return '/paginado/ativos/filtro?status=true&'
        case 'Inativos':
          return '/paginado/inativos/filtro?status=false&'
        case 'Todos':
          return '/paginado/filtro/?';
      }
    })();

    store.commit("setRequisicao", true);
    if (isGestor.value) {
      return http
        .get(
          `/usuario/gestor/${usuario.value.id}${chamada}filtro=${filtro}&pagina=${pagina}&tamanhoPagina=8`
        )
        .then((response) => {
          store.commit(
            "instrutorModule/setInstrutoresPaginado",
            response.data.content
          );
          store.commit(
            "instrutorModule/setQuantidadeInstrutores",
            response.data.totalElements
          );
          store.commit("setRequisicao", false);
          store.commit("setErros", "");
        })
        .catch((error) => {
          store.commit("setRequisicao", false);
          store.commit("setErros", error.response.data);
        });
    } else if (isSupervisor.value) {
      return http
        .get(
          `/usuario/supervisor/${usuario.value.id}${chamada}filtro=${filtro}&pagina=${pagina}&tamanhoPagina=8`
        )
        .then((response) => {
          store.commit(
            "instrutorModule/setInstrutoresPaginado",
            response.data.content
          );
          store.commit(
            "instrutorModule/setQuantidadeInstrutores",
            response.data.totalElements
          );
          store.commit("setRequisicao", false);
          store.commit("setErros", "");
        })
        .catch((error) => {
          store.commit("setRequisicao", false);
          store.commit("setErros", error.response.data);
        });
    } else {
      return http
        .get(`/usuario${chamada}filtro=${filtro}&pagina=${pagina}&tamanhoPagina=8`)
        .then((response) => {
          store.commit(
            "instrutorModule/setInstrutoresPaginado",
            response.data.content
          );
          store.commit(
            "instrutorModule/setQuantidadeInstrutores",
            response.data.totalElements
          );
          store.commit("setRequisicao", false);
          store.commit("setErros", "");
        })
        .catch((error) => {
          store.commit("setRequisicao", false);
          store.commit("setErros", error.response.data);
        });
    }
  },

  async getByLogin(login) {
    return http
      .get(`/usuario/buscar/login/${login}`)
      .then((response) => {
        if (
          !response.data.status &&
          !window.location.href.includes("/unauthorized/")
        ) {
          window.location.href = "/unauthorized/";
        }

        store.commit("authModule/changeUser", response.data);
        store.commit("setErros", "");
      })
      .catch((error) => store.commit("setErros", error.response.data));
  },

  async getFiltrarInstrutoresPorTreinamento(id, dataInicial, dataFinal) {
    return http
      .get(`/usuario/treinamento/${id}`, {
        params: {
          dataInicial: dataInicial,
          dataFinal: dataFinal,
        },
      })
      .then((response) => {
        store.commit(
          "instrutorModule/setFiltrarInstrutoresPorTreinamento",
          response.data
        );
        store.commit("setErros", "");
      })
      .catch((error) => store.commit("setErros", error.response.data));
  },

  async getFiltrarInstrutoresPorPeriodo(dataInicial, dataFinal) {
    return http
      .get("/usuario/periodo/", {
        params: {
          dataInicial: dataInicial,
          dataFinal: dataFinal,
        },
      })
      .then((response) => {
        store.commit(
          "instrutorModule/setFiltrarInstrutoresPorPeriodo",
          response.data
        );
        store.commit("setErros", "");
      })
      .catch((error) => store.commit("setErros", error.response.data));
  },

  async createInstrutor(dados) {
    return http
      .post("/usuario", {
        nome: dados.nome,
        login: dados.login,
        filial: dados.filial,
        privilegio: dados.privilegio.toLocaleUpperCase(),
        canal: dados.canal,
        status: dados.status,
      })
      .then((response) => {
        store.commit("setErros", "");
        this.getInstrutoresPaginado(paginaSelecionada.value);
        return response;
      })
      .catch((error) => store.commit("setErros", error.response.data));
  },

  async editInstrutor(dados) {
    return http
      .put(`/usuario/${dados.id}`, {
        nome: dados.nome,
        login: dados.login,
        filial: dados.filial,
        privilegio: dados.privilegio.toLocaleUpperCase(),
        canal: dados.canal,
        status: dados.status == "Ativo" ? true : false,
      })
      .then((response) => {
        const index = instrutores.value.indexOf(
          instrutores.value.filter((instrutor) => instrutor.id == dados.id)[0]
        );
        store.commit("instrutorModule/editInstrutor", {
          index,
          data: response.data,
        });
        store.commit("setErros", "");
        this.getInstrutoresPaginado(paginaSelecionada.value);
      })
      .catch((error) => store.commit("setErros", error.response.data));
  },

  async alterarStatus(dados) {
    return http
      .patch(`/usuario/alterarStatus/${dados.id}`)
      .then(() => {
        dados.status = dados.status == "Ativo" ? "Inativo" : "Ativo";
        store.commit("setErros", "");
      })
      .catch((error) => store.commit("setErros", error.response.data));
  },
};

export default InstrutorAPI;
