import { createStore } from "vuex"
import VuexPersistence from "vuex-persist"
import authModule from "./modules/authModule"
import avaliacaoModule from "./modules/avaliacaoModule"
import comentariosSugestoesModule from "./modules/comentariosSugestoesModule"
import estatisticaModule from "./modules/estatisticaModule"
import formularioModule from "./modules/formularioModule"
import instrutorModule from "./modules/instrutorModule"
import layoutsModule from "./modules/layoutsModule"
import perguntaModule from "./modules/perguntaModule"
import treinamentoModule from "./modules/treinamentoModule"

const excludedModules = ["layoutsModule", "perguntaModule"];

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => {
    const persistedState = {};
    for (const moduleName in state) {
      if (!excludedModules.includes(moduleName)) {
        persistedState[moduleName] = state[moduleName];
      }
    }
    return persistedState;
  },
});

const store = createStore({
  state: {
    themeIsDark: false,
    tokenCertificado: process.env.VUE_APP_TOKEN_CERTIFICADO,
    urlAvaliacoes: process.env.VUE_APP_URL_AVALIACOES,
    urlCertificado: process.env.VUE_APP_URL_CERTIFICADO,
    icones: [
      ["Formulários", "fa-solid fa-file-lines"],
      ["Relatórios", "fa-solid fa-chart-pie"],
      ["Instrutores", "fa-solid fa-person-chalkboard"],
      ["Treinamentos", "fa-solid fa-graduation-cap"],
      ["Avaliações", "fa-solid fa-clipboard-list"],
    ],
    instrutorSelecionado: {
      nome: "",
      login: "",
      filial: "",
      privilegio: "",
      canal: "",
    },
    treinamentoSelecionado: {
      nome: "",
    },
    avaliacaoSelecionada: {
      id: "",
      nomeCompleto: "",
      email: "",
      treinamento: "",
      fimTreinamento: "",
      cargaHoraria: "",
    },
    expandirBarraLateral: false,
    acaoModal: "",
    erros: "",
    requisicao: false,
  },
  getters: {},
  mutations: {
    setExpandirBarraLateral(state, payload) {
      state.expandirBarraLateral = payload;
    },
    setRequisicao(state, payload) {
      state.requisicao = payload;
    },
    setThemeIsDark(state, payload) {
      state.themeIsDark = payload;
    },
    setTreinamentoSelecionado(state, payload) {
      state.treinamentoSelecionado = payload;
    },
    setInstrutorSelecionado(state, payload) {
      state.instrutorSelecionado = payload;
    },
    setAvaliacaoSelecionada(state, payload) {
      state.avaliacaoSelecionada = payload;
    },
    setAcaoModal(state, payload) {
      state.acaoModal = payload;
    },
    setErros(state, payload) {
      state.erros = payload;
    },
  },
  actions: {},
  modules: {
    instrutorModule,
    perguntaModule,
    treinamentoModule,
    layoutsModule,
    formularioModule,
    authModule,
    estatisticaModule,
    comentariosSugestoesModule,
    avaliacaoModule,
  },
  plugins: [vuexLocal.plugin],
});

export default store;
